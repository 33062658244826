import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconActivatingPriorKnowledge from 'stile-shared/assets/images/evidence-based-pedagogy/activating_prior_knowledge_us.png';
import iconCollaborativeProblemSolving from 'stile-shared/assets/images/evidence-based-pedagogy/collaborative_problem_solving_us.png';
import iconExplicitTeaching from 'stile-shared/assets/images/evidence-based-pedagogy/explicit_teaching_us.png';
import iconFeedback from 'stile-shared/assets/images/evidence-based-pedagogy/feedback_us.png';
import iconFormativeAssessment from 'stile-shared/assets/images/evidence-based-pedagogy/formative_assessment_us.png';
import iconMetacognition from 'stile-shared/assets/images/evidence-based-pedagogy/metacognition_us.png';
import iconRealWorld from 'stile-shared/assets/images/evidence-based-pedagogy/real-world_us.png';
import iconRevision from 'stile-shared/assets/images/evidence-based-pedagogy/revision_us.png';
import iconStudentInquiry from 'stile-shared/assets/images/evidence-based-pedagogy/student_inquiry_us.png';
import iconStudentsSolutions from 'stile-shared/assets/images/evidence-based-pedagogy/students_solutions_us.png';
import iconSummativeAssessment from 'stile-shared/assets/images/evidence-based-pedagogy/summative_assessment_us.png';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H3, P } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Icon } from 'stile-shared/src/components/2020/layout/Icon';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function EvidenceBasedPedagogyPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/evidence-based-pedagogy/cover_evidence-based_pedagogy.png"
            alt="A teacher and student in the midst of a classroom discussing his work"
          />
        }
      />

      <TextBlock>
        <P>
          Stile’s learning resources, technology and professional learning offerings have been
          crafted to make it easy to teach the Next Generation Science Standards (NGSS). We draw on
          phenomenon-based, student-driven, three-dimensional approaches to science education.
        </P>
      </TextBlock>

      <TextBlock title="Pedagogy for the classroom">
        <Column space={theme.space.l}>
          <P>
            Stile’s <Link url="/what-is-stile/stile-classroom/">classroom resources</Link> are built
            to support pedagogies where teachers are highly involved in crafting and directing the
            learning experience. The pedagogical flow typically looks like this:
          </P>
          <StaticImage
            src="../../../../stile-shared/assets/images/evidence-based-pedagogy/diagram_inclass_us.png"
            alt="A circular diagram with several layers. The outer ring has principles of activating student knowledge such as 
            engaging in real-world phenomena and designing solutions. These flow into the second layer, where student inquiry 
            leads to explicit teaching and collaborative problem solving. This is then assessed through metacognition, which 
            leads back to student inquiry via revision, or by formative assessment, which leads back to student inquiry via 
            feedback and differentiation. Finally, both of these lead to summative assessment"
          />
        </Column>
      </TextBlock>

      <TextBlock title="Stile is the ultimate Curriculum to encourage evidence-based teaching strategies">
        <Column space={theme.space.lToXl}>
          <P>Learn more about the proven strategies that underpin everything we do!</P>

          <Column>
            <H3>
              <Icon
                src={iconRealWorld}
                text="Students engage in real-world phenomena"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>

            <P>
              Every Stile unit is centered around an intriguing phenomenon, intentionally selected
              to motivate curiosity. Upon their introduction to the anchoring phenomenon, students
              are driven to ask questions and seek answers that explain it.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconStudentsSolutions}
                text="Students design solutions"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Stile’s lessons draw direct links between real-world technologies and the science that
              underpins them. In all Stile units, students engage in phenomena, ask questions, and
              design possible solutions anchored by the real world.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconActivatingPriorKnowledge}
                text="Activating prior knowledge"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Every student contributes a unique history to the classroom environment. To
              effectively build on their existing understanding, learners must forge connections
              between their individual past experiences and the new information they encounter.
              Stile units include opportunities for students to reflect on their current knowledge
              and experiences, providing a sound foundation from which they can build new learning.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconStudentInquiry}
                text="Student inquiry and discovery"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Once students have been introduced to the anchoring phenomenon, they’ll be itching to
              get going. The process of inquiry, however, requires intentional scaffolding by the
              teacher to ensure that students achieve success. Stile’s lessons and teacher support
              materials offer direction and guidance to ensure students are achieving the intended
              outcomes, while still allowing them to drive the learning process.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconExplicitTeaching}
                text="Explicit teaching and setting learning goals"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              While student curiosity and questioning drives them through learning goals, evidence
              shows that students who know what is expected of them are more likely to engage in the
              learning process (Hattie, 2012). Each Stile lesson identifies learning goals that
              describe what students should know, understand, and be able to do.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconCollaborativeProblemSolving}
                text="Collaborative problem-solving"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Stile’s platform is designed to be used as a blended learning tool, where technology
              enhances classroom experiences. This means that students and teachers engage with the
              lesson together and students work cooperatively to explore and unpack the anchoring
              phenomenon. Students have the chance to express their ideas in pairs, small groups,
              and whole-class situations. Collaborative widgets, such as the Live Brainstorm and the
              Live Poll, are particularly effective for facilitating conversation between students.
            </P>
            <P>
              Lab activities, Socratic seminars, and engineering challenges have students working in
              groups to complete investigations, design solutions, and engage in scientific
              argument. Working together helps students to develop communication and social skills,
              and provides each member of the group with access to alternative perspectives through
              their peers’ ideas.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconMetacognition}
                text="Metacognition"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Students are continually encouraged to build an understanding of, and reflect upon,
              the process of their own learning. As they work through a unit, they consider what
              they need to work on and how they can improve, using the learning goals as their point
              of reference. Self-assessment checkpoints found throughout a unit are included as
              useful prompts to consider their confidence in relation to a lesson’s learning goal.
              Visible Thinking Routines are also incorporated to guide the process of scientific
              thinking, and to support students to express and share their ideas (Ritchhart, Church
              and Morrison, 2011).
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconFormativeAssessment}
                text="Formative assessment"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Formative assessment informs the teaching and learning process for both students and
              teachers. Teach Mode, a feature within Stile’s online platform, gives teachers access
              to live analytics that lets them formatively assess work and respond to students’
              needs on the spot. Combined with Analyze Mode, which shows a summary of student
              progress within a lesson, teachers have everything they need to determine their next
              teaching steps. Key Questions are questions that match the success criteria of the
              lesson and allow teachers to formatively assess student learning.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconFeedback}
                text="Feedback, reflection, and differentiation"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Students receive feedback in many forms in Stile. As they work, teachers can provide
              verbal feedback to guide their progress. Upon submission, Stile grades and gives
              automated feedback for multiple choice and Interactive Canvas questions, before
              teachers release model answers for all question types. These various forms of feedback
              provide students with information from which they can reflect and determine their next
              steps. They also allow teachers to differentiate learning material to support students
              in achieving learning goals, whether by modifying content or applying specific
              teaching strategies.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconRevision}
                text="Revision and mastery"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Research suggests that note-taking is best when done with a pen and paper. In fact,
              compared with digital note-taking, handwritten notes have a higher impact on cognitive
              understanding, recall, and grades (Mueller & Oppenheimer, 2014).
            </P>
            <P>
              <Link url="/what-is-stile/stile-x/">Stile X </Link>
              is a student notebook and revision guide that promotes student agency and helps
              students learn how to study, while they study. Rigorous scaffolding within Stile X
              supports students in taking handwritten notes to consolidate knowledge from class.
            </P>
            <P>
              The glossary section lets them record their own definitions for scientific
              terminology, and test preparation questions allow students to check their
              understanding against learning goals.
            </P>
            <P>
              The accompanying Stile X phone app incorporates 60-second summary videos, flashcards,
              flash quizzes, model answers, and an interactive glossary to further support students
              in reviewing the learning material.
            </P>
          </Column>

          <Column>
            <H3>
              <Icon
                src={iconSummativeAssessment}
                text="Summative assessment"
                space={theme.space.xs}
                iconSize="2.75rem"
                inline={false}
              />
            </H3>
            <P>
              Units in Stile include tests, engineering challenges, and Socratic seminars as
              summative assessment opportunities. Tests include a variety of question types and
              three-dimensional questions are incorporated to assess Disciplinary Core Ideas,
              Science and Engineering Practices, and Crosscutting Concepts simultaneously.
              Engineering challenges and Socratic seminars require students to apply a
              three-dimensional approach to problem-solving and include detailed rubrics against
              which both peer assessment, self-assessment, and teacher assessment can be completed.
            </P>
          </Column>
        </Column>
      </TextBlock>

      <NextPageBlock path="/why-choose-stile/stem/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default EvidenceBasedPedagogyPage;
